<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo/logorodopetit.png" alt="Logo" height="32" />
        <h2 class="brand-text text-primary ml-1">Staff Control</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Staff Control! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="User" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|no-pda">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="mbc@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox 
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox>
              </b-form-group>-->

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="validationForm">
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <b-button type="submit" variant="info" block @click="adfsLogin">
            BSM - Sign in
          </b-button>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@/libs/axios";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ability from "@/libs/acl/ability";
import { extend } from "vee-validate";
import { encryptString } from "@/libs/crypto";

extend("no-pda", {
  validate: (value) => {
    if (value && /^(pda|sj|fm)\./.test(value.toLowerCase())) {
      // sj. fm.
      // /^(pda\.|PDA\.|pda|PDA)/i.test(value)
      return "Invalid user";
    }
    return true;
  },
});

export default {
  components: {
    BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, ValidationProvider, ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      AdminAbilities: [
        {
          action: "manage",
          subject: "all",
        },
      ],
      UserAbilities: [],
      sideImg: require("@/assets/images/pages/acreditation.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  async mounted() {
    // console.log(this.$route.query.user);
    if (!this.$route.query.user) {

    } else {

      await axios
        .post(`/login`, {
          username: this.$route.query.user,
          token: this.$route.query.token,
          active_directory: true
        })
        .then((response) => {
          if (response.data[0].role === "Admin") {
            response.data[0].ability = this.AdminAbilities;
          } else if (response.data[0].role === "User") {
            response.data[0].ability = this.UserAbilities;
          } else {
            response.data[0].ability = this.DefaultAbilities;
          }
          localStorage.setItem("userData", JSON.stringify(response.data[0]));
          localStorage.setItem("idUser", response.data[0].ID_user);
          this.$ability.update(response.data[0].ability);

          localStorage.setItem("accessToken", response.data[0].accessToken);
          localStorage.setItem("Aforo_permit", response.data[0].Aforo_permit);
          /*this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data[0].token,
                  icon: "EditIcon",
                  variant: "success",
                },
              });*/
        })
        .catch((error) => {
          const variant = "danger";
          this.$bvToast.toast("User or password incorrect.", {
            title: `` + error,
            variant,
            solid: true,
          });
        });
      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router
        .replace(getHomeRouteForLoggedInUser("admin"))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Welcome `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully logged in.`,
            },
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/acreditation.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(async (success) => {
        if (success) {
          await axios
            .post(`/login`, {
              username: this.userEmail,
              password: this.password,
            })
            .then(async (response) => {
              localStorage.setItem("accessToken", response.data[0].accessToken);

              await axios
                .get(`/user-web-permissions?ID_user=${response.data[0].ID_user.toString()}`)
                .then((res) => {
                  res.data.forEach(element => {
                    this.UserAbilities.push(element);
                  });
                })
                .catch((error) => {
                  console.log(error)
                  const variant = "danger";
                  this.$bvToast.toast("User don\'t have permissions ", {
                    title: `` + error,
                    variant,
                    solid: true,
                  })
                });

              await axios
              .get(`/user-task-permissions?ID_user=${response.data[0].ID_user.toString()}`)
              .then((res) => {
                res.data.forEach(element => {
                  this.UserAbilities.push(element);
                });
              })
              .catch((error) => {
                console.log(error)
                const variant = "danger";
                this.$bvToast.toast("User don\'t have permissions ", {
                  title: `` + error,
                  variant,
                  solid: true,
                })
              });

              if (response.data[0].role === "Admin") {
                response.data[0].ability = this.AdminAbilities;
                // ability.update(this.AdminAbilities);
              } else {
                response.data[0].ability = this.UserAbilities;
                // ability.update(response.data[0].ability);
              }
              ability.update(response.data[0].ability);

              localStorage.setItem("userData", JSON.stringify(response.data[0]));
              localStorage.setItem("idUser", encryptString(response.data[0].ID_user.toString()));
              localStorage.setItem("Aforo_permit", encryptString(response.data[0].Aforo_permit.toString()));
            })
            .catch((error) => {
              console.log(error)
              const variant = "danger";
              this.$bvToast.toast("User or password incorrect.", {
                title: `` + error,
                variant,
                solid: true,
              })
            });

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          this.$router
            .replace(getHomeRouteForLoggedInUser("admin"))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully logged in.`,
                },
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async adfsLogin() {
      window.location.href = "https://bsmstaffcontrol.mbcontrol.cat:44395/adfs/login";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
